export const createNotificationSubscription = `
    mutation createNotificationSubscription($region:String!, $input: CreateSubscriptionInput!) {
        createSubscription(region:$region, input:$input) {
            subscriptionArn
            endpoint
            owner
            protocol
            topicArn
            created
            updated
    }
}`;

export const updateNotificationSubscription = `
    mutation updateNotificationSubscription($region:String!, $input: UpdateSubscriptionInput!) {
        updateSubscription(region:$region, input:$input){
            subscriptionArn
            endpoint
            owner
            protocol
            topicArn
            created
            updated
        }
}`;

export const deleteNotificationSubscription = `
    mutation deleteNotificationSubscription($region:String!, $input: DeleteSubscriptionInput!) {
        deleteSubscription(region:$region, input:$input) 
}`;
