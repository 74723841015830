import {
    createContext,
    FunctionComponent,
    ReactNode,
} from 'react';

import {FlexiButton} from '@flexinet/ui-components';

import {Modal} from '@mantine/core';
import {useDisclosure} from '@mantine/hooks';
import {
    AiOutlineEdit,
    AiOutlinePlus,
} from 'react-icons/ai';


export type ModalContextType = { closeModal: () => void; openModal: () => void; }
export const ModalContext = createContext<ModalContextType>({} as ModalContextType);

type ButtonVariant = 'filled' | 'outline' | 'subtle'
type IconType = 'add' | 'edit'

type ModalContextProviderType = {
    buttonOptions: {
        variant: ButtonVariant,
        label: string,
        iconType: IconType,
        size?: string
    },
    title: string,
    onConfirm?: () => void,
    children: ReactNode
}

export const ModalContextProvider: FunctionComponent<ModalContextProviderType> = (
    {
        buttonOptions = {
            variant: 'filled',
            label: 'Add Record',
            iconType: 'add',
        },
        title = 'All Records',
        children,
    },
) => {

    const [opened, {
        close,
        open,
    }] = useDisclosure(false);


    return (
        <ModalContext.Provider
            value={{
                openModal: open,
                closeModal: close,
            }}
        >
            <Modal opened={opened} onClose={close} size='50%' title={title}>
                {children}
            </Modal>
            <FlexiButton
                variant={buttonOptions.variant}
                leftSection={
                    buttonOptions.iconType === 'add' ? (
                        <AiOutlinePlus/>
                    ) : (
                        <AiOutlineEdit/>
                    )
                }
                onClick={open}
            >
                {buttonOptions.label}
            </FlexiButton>

        </ModalContext.Provider>
    );
};