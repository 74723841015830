import {
    deleteRegistration,
    fetchRegistration,
    saveRegistration,
    updateRegistration,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {Registration} from '../types';

export const useRegistration = (registrationId?: string) => {


    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, registrationId ? registrationId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof registrationId === 'undefined') {
            throw new Error('registrationId is required parameter');
        }
        dispatch(fetchRegistration(registrationId));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: Registration) => {
        dispatch(saveRegistration(values));
    };

    const updateRecord = (values: Registration) => {
        if (typeof registrationId === 'undefined') {
            throw new Error('registrationId is required parameter');
        }

        dispatch(updateRegistration({
                                        id: registrationId,
                                        record: values,
                                    }));
    };

    const deleteRecord = () => {
        if (typeof registrationId === 'undefined') {
            throw new Error('registrationId is required parameter');
        }
        dispatch(deleteRegistration(registrationId));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
