import {stage} from '../stage';


const marketplaceAPI = {
    prod: {
        endpoint: 'https://xgia22a3dfek3p3aljh7rnfayy.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
    },
    test: {
        endpoint: 'https://dgmskmw43bfavpnx3rlmv2d2ly.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
    },
    dev: {
        endpoint: 'https://vejajmccq5gspenpmdaxp4usky.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
    },
    ide: {
        endpoint: 'https://vejajmccq5gspenpmdaxp4usky.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
    },
};

export const appSyncConfig = marketplaceAPI[stage];

