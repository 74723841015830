import PropTypes from 'prop-types';
import {Field} from 'formik';

import {
    FormikDateTimePicker,
    FormikNumberInput,
    FormikSelect
} from '@flexinet/ui-components';
import {
    Box,
    Fieldset,
    Text,
    Title
} from "@mantine/core";
import {FormikList} from "../../../components/FormikList";

const usageRecordsListRowElements = [
    {
        name: 'customerIdentifier',
        placeholder: 'Customer Identifier',
        component: FormikSelect,
        data: [
            {
                value: '58038',
                label: '58038'
            },
            {
                value: '58039',
                label: '58039'
            }
        ],
        span: 3,
    },
    {
        name: 'dimension',
        placeholder: 'Dimension',
        component: FormikSelect,
        data: [
            {
                value: 'units',
                label: 'Units'
            },
            {
                value: 'users',
                label: 'Users'
            }
        ],
        span: 3,
    },
    {
        name: 'quantity',
        placeholder: 'Quantity',
        component: FormikNumberInput,
        span: 3,
    },
    {
        name: 'timestamp',
        placeholder: 'Timestamp',
        component: FormikDateTimePicker,
        span: 3,
    },

]


export const FormComponent = () => {

    return (
        <Box>
            <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Metering Record</Text></Title>} px={'xl'}
                      mb={'xl'}>
                <Field
                    name='productCode'
                    label='Product Code'
                    description='Please enter the product code'
                    component={FormikSelect}
                    data={[
                        {
                            value: '807847',
                            label: '807847'
                        },
                        {
                            value: '127484',
                            label: '127484'
                        }
                    ]}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='usageRecords'
                    label='Usage Records'
                    description='Please enter the Usage Records'
                    component={FormikList}
                    getRowElements={() => {
                        return usageRecordsListRowElements
                    }}
                    withAsterisk
                />

            </Fieldset>

        </Box>
    );
};

FormComponent.propTypes = {
    values: PropTypes.object.isRequired,
};
