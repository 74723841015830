import {
    Grid,
    Stack,
} from '@mantine/core';
import {ProfileWidget} from "./dashboard/ProfileWidget.tsx";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";

export const PortalDashboard = () => {

    const {allowedActions} = useVerifiedPermissions()

    return (
        <Grid mx={'10%'}>
            <Grid.Col span={{
                xl: 6,
                lg: 6,
                md: 12,
                sm: 12,
                xs: 12,
            }}>
                <Stack>
                    {allowedActions.includes('ViewProfile') ? <ProfileWidget/> : null}
                </Stack>
            </Grid.Col>

            <Grid.Col span={{
                xl: 6,
                lg: 6,
                md: 12,
                sm: 12,
                xs: 12,
            }}>
                <Stack>
                </Stack>
            </Grid.Col>
        </Grid>
    );
};
