import type {TypedUseSelectorHook} from 'react-redux'
import {
    useDispatch,
    useSelector
} from 'react-redux'

import type {RootState} from '../store/index'


// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<any>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector