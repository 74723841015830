import PropTypes from 'prop-types';
import {Field} from 'formik';

import {
    FormikTextarea,
    FormikTextInput
} from '@flexinet/ui-components';
import {Grid} from "@mantine/core";


export const FormComponent = () => {

    return (
        <Grid>
            <Grid.Col span={12}>
                <Field
                    name='name'
                    label='Name'
                    description='Please enter the offer name'
                    component={FormikTextInput}
                    withAsterisk
                />
            </Grid.Col>

            <Grid.Col span={12}>
                <Field
                    name='description'
                    label='Description'
                    description='Please enter offer description'
                    component={FormikTextarea}
                />

            </Grid.Col>

            <Grid.Col span={12}>
                <Field
                    name='email'
                    label='Email'
                    description='Please enter offer email'
                    component={FormikTextInput}
                />
            </Grid.Col>

            <Grid.Col span={12}>
                <Field
                    name='phone'
                    label='Phone'
                    description='Please enter offer phone number'
                    component={FormikTextInput}
                />
            </Grid.Col>


        </Grid>
    );
};

FormComponent.propTypes = {
    values: PropTypes.object.isRequired,
};
