import PropTypes from 'prop-types';
import {Field} from 'formik';

import {
    FormikCreatableSelect,
    FormikMultiSelect,
    FormikRadioGroup,
    FormikTextarea,
    FormikTextInput
} from '@flexinet/ui-components';
import {
    Container,
    Fieldset,
    Text,
    Title
} from "@mantine/core";


export const FormComponent = ({values}: { values: { id: string } }) => {

    const editMode = values.id ? true : false;

    return (
        <Container>
            <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Product</Text></Title>} px={'xl'} mb={'xl'}>
                <Field
                    name='productTitle'
                    label='Product Title'
                    description='Please enter the product title'
                    component={FormikTextInput}
                    withAsterisk
                    my={'xl'}
                />
                <Field
                    name='productId'
                    label='Product ID'
                    description='Please enter product ID'
                    component={FormikTextInput}
                    withAsterisk
                    my={'xl'}
                />
                <Field
                    name='productArn'
                    label='Product ARN'
                    description='Please enter product ARN'
                    component={FormikTextInput}
                    withAsterisk
                    my={'xl'}
                />

            </Fieldset>

            {editMode ? <>
                <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Integration</Text></Title>} px={'xl'}
                          mb={'xl'}>
                    <Field
                        name='productCode'
                        label='Product Code'
                        description='Please enter product code'
                        component={FormikTextInput}
                        withAsterisk
                        my={'xl'}
                    />


                    <Field
                        name='meteringServiceSnsTopicArn'
                        label='Metering Service Sns Topic ARN'
                        description='Please enter topic arn'
                        component={FormikTextInput}
                        withAsterisk
                        my={'xl'}
                    />
                </Fieldset>


                <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Status</Text></Title>} px={'xl'} mb={'xl'}>

                    <Field
                        name='status'
                        label='Status'
                        description='Please enter status'
                        component={FormikRadioGroup}
                        data={[
                            {
                                value: 'Staging',
                                label: 'Staging'
                            },
                            {
                                value: 'Pending',
                                label: 'Pending'
                            },
                            {
                                value: 'Limited',
                                label: 'Limited'
                            },
                            {
                                value: 'Active',
                                label: 'Active'
                            },
                            {
                                value: 'Deleted',
                                label: 'Deleted'
                            },
                            {
                                value: 'Unknown',
                                label: 'Unknown'
                            },]}
                        my={'xl'}
                    />

                    <Field
                        name='sku'
                        label='SKU'
                        description='Please enter sku'
                        component={FormikTextInput}
                        my={'xl'}
                    />

                </Fieldset>


                <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Description</Text></Title>} px={'xl'}
                          mb={'xl'}>

                    <Field
                        name='shortDescription'
                        label='Short Description'
                        description='Please enter short product description'
                        component={FormikTextarea}
                        withAsterisk
                        my={'xl'}
                    />

                    <Field
                        name='longDescription'
                        label='Long Description'
                        description='Please enter long product description'
                        component={FormikTextarea}
                        withAsterisk
                        my={'xl'}
                    />

                </Fieldset>


                <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Media</Text></Title>} px={'xl'} mb={'xl'}>


                    <Field
                        name='productLogoS3Url'
                        label='Product Logo S3 URL'
                        description='Please enter s3 bucket url'
                        component={FormikTextInput}
                        withAsterisk
                        my={'xl'}
                    />

                    <Field
                        name='productVideoUrl'
                        label='Product Video URL'
                        description='Please enter video url'
                        component={FormikTextInput}
                        my={'xl'}
                    />
                </Fieldset>

                <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Highlights</Text></Title>} px={'xl'}>

                    <Field
                        name='highlightOne'
                        label='Highlight One'
                        description='Please enter highlight one'
                        component={FormikTextarea}
                        withAsterisk
                        my={'xl'}
                    />

                    <Field
                        name='highlightTwo'
                        label='Highlight Two'
                        description='Please enter highlight two'
                        component={FormikTextarea}
                        my={'xl'}
                    />

                    <Field
                        name='highlightThree'
                        label='Highlight Three'
                        description='Please enter highlight three'
                        component={FormikTextarea}
                        my={'xl'}
                    />
                </Fieldset>


                <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Support Options</Text></Title>} px={'xl'}>
                    <Field
                        name='supportDetails'
                        label='Support Details'
                        description='Please enter support details'
                        component={FormikTextarea}
                        my={'xl'}
                    />
                </Fieldset>


                <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Discoverability</Text></Title>} px={'xl'}
                          mb={'xl'}>

                    <Field
                        name='productCategories'
                        label='Product Categories'
                        description='Please enter product categories'
                        component={FormikMultiSelect}
                        data={['infrastructure', 'devops', 'business']}
                        my={'xl'}
                    />

                    <Field
                        name='keywordsForSearchResults'
                        label='Keywords For Search Results'
                        description='Please enter keywords'
                        component={FormikCreatableSelect}
                        my={'xl'}
                    />

                </Fieldset>


            </> : null}


        </Container>
    );
};

FormComponent.propTypes = {
    values: PropTypes.object.isRequired,
};
