import {
    Button,
    Group,
    Radio,
    Stack,
    TextInput
} from "@mantine/core";
import {config} from "../../../config/amplify/api.ts";
import {IndexPageNavigation} from "../components/IndexPageNavigation.tsx";
import {FormPageLayout} from "@flexinet/ui-components";


export const SimulatePage = () => {

    const randomToken = Math.random()
                            .toString(36)
                            .substring(2, 15) + Math.random()
                                                    .toString(36)
                                                    .substring(2, 15);


    const element = Object()
        .keys(config)
        .find((item: { name: string }) => item.name === 'FLEXI_FULFILLMENT_API');

    if (typeof element === 'undefined') {
        throw new Error('endpoint is undefined')
    }

    return (
        <FormPageLayout
            title='Simulate Registration'
            pageNavigation={<IndexPageNavigation/>}
        >


            <form
                method="post"
                action={element.endpoint}
                target="_blank"
            >
                <Stack>

                    <TextInput
                        name="x-amzn-marketplace-token"
                        label='Marketplace Token'
                        value={randomToken}
                        my={'sm'}
                    />

                    <Radio.Group
                        name="environment"
                        label="Select your environment"
                        withAsterisk
                        my={'sm'}
                    >
                        <Group mt="xs">
                            <Radio value="dev" label="Dev"/>
                            <Radio value="test" label="Test"/>
                            <Radio value="prod" label="Prod"/>
                        </Group>

                    </Radio.Group>

                </Stack>

                <div>
                    <Button
                        type={"submit"}
                        my={'sm'}
                    >Submit</Button>
                </div>
            </form>

        </FormPageLayout>
    );
}

