import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update,
} from '../services/NotificationSubscriptionsService.ts';


import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';
import {NotificationSubscription} from '../types';
import getUuid from "uuid-by-string";
import {recordSelector as productSelector} from "../../products";
import {recordSelector} from "../selectors.ts";

const entityKey = 'notificationSubscriptions';

interface NotificationSubscriptionsState {
    byId: { [key: string]: NotificationSubscription },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string
}

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as NotificationSubscriptionsState;


const normalizeRecord = (record: NotificationSubscription) => {
    return Object.assign({}, record, {
        id: getUuid(record.topicArn as string + record.endpoint as string)
    });
};

// generates pending, fulfilled and rejected
export const fetchNotificationSubscriptions = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    (productId: string, {getState}) => {

        const state = getState();
        const {record} = productSelector(state, productId)
        const {meteringServiceSnsTopicArn} = record;

        return fetchRecords(meteringServiceSnsTopicArn)
            .then((response) => {
                if (response) {
                    return response.map((record: NotificationSubscription) => {
                        return normalizeRecord(record);
                    });
                }
            });
    },
);

export const fetchNotificationSubscription = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string, {getState}) => {

        const state = getState();
        const {record} = recordSelector(state, id)
        const {subscriptionArn} = record;

        return fetch(subscriptionArn)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return normalizeRecord(response);
                }
            });
    });

export const saveNotificationSubscription = createAsyncThunk(
    `${entityKey}/add`,
    (payload: NotificationSubscription) => {
        return add(payload)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const updateNotificationSubscription = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record,
     }: { id: string, record: NotificationSubscription }) => {

        return update(id, record)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    },
);

export const deleteNotificationSubscription = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string, {getState}) => {

        const state = getState();
        const {record} = recordSelector(state, id)
        console.debug('fetchRecords variables: ', record);
        const {subscriptionArn} = record;
        console.log(subscriptionArn);

        return deleteRecord(subscriptionArn)
            .then(() => id);
    },
);


export const storeNotificationSubscription = createAsyncThunk(
    `${entityKey}/store`,
    (payload: NotificationSubscription) => {
        return normalizeRecord(payload);
    },
);


const componentsSlice = createSlice({
                                        name: entityKey,
                                        initialState,
                                        reducers: {
                                            clearState: () => initialState,
                                        },
                                        extraReducers: (builder) => {
                                            // FETCH MANY
                                            fetchManyCase(builder, fetchNotificationSubscriptions, entityKey);

                                            // FETCH ONE
                                            fetchOneCase(builder, fetchNotificationSubscription);

                                            // SAVE ONE
                                            saveOneCase(builder, saveNotificationSubscription);

                                            // UPDATE ONE
                                            updateOneCase(builder, updateNotificationSubscription);

                                            updateOneCase(builder, storeNotificationSubscription);

                                            // DELETE ONE
                                            deleteOneCase(builder, deleteNotificationSubscription);
                                        },
                                    });

export default componentsSlice.reducer;
