import {Product,} from '../../types';
import {
    PlatformPropertyCard,
    PlatformPropertyCardCopyButtonDecorator,
    PlatformPropertyCardProperty,
    PlatformPropertyCardTimestampFormatter,
    TabbedComponent,
    TabComponentTab
} from "@flexinet/ui-components";
import {useAllowedItems} from "../../../../hooks/useAllowedProperties.ts";


export const Profile = ({record}: { record: Product }) => {


    const properties = [
        {
            label: 'Product Title',
            value: record.productTitle,
            decorator: (value: string) => <PlatformPropertyCardCopyButtonDecorator value={value}/>,
            span: 6,
        }, {
            label: 'Product ID',
            value: record.productId as string,
            decorator: (value: string) => <PlatformPropertyCardCopyButtonDecorator value={value}/>,
            span: 6,
        }, {
            label: 'Product Code',
            value: record.productCode as string,
            decorator: (value: string) => <PlatformPropertyCardCopyButtonDecorator value={value}/>,
            span: 6,
        }, {
            label: 'Status',
            value: record.status as string,
            span: 6,
        }, {
            label: 'Product ARN',
            value: record.productArn as string,
            span: 12,
        }] as PlatformPropertyCardProperty[];


    const {filterAllowed} = useAllowedItems()
    const allowedProperties = filterAllowed(properties) as PlatformPropertyCardProperty[];
    const tabs = [{
        title: 'Details',
        component: <PlatformPropertyCard properties={allowedProperties}/>
    }, {
        title: 'Description',
        component: <PlatformPropertyCard properties={[{
            label: 'Short Description',
            value: record.shortDescription as string,
            decorator: (value: string) => <PlatformPropertyCardCopyButtonDecorator value={value}/>,
            span: 12,
        }, {
            label: 'Long Description',
            value: record.longDescription as string,
            decorator: (value: string) => <PlatformPropertyCardCopyButtonDecorator value={value}/>,
            span: 12,
        }]}/>
    }, {
        title: 'Media',
        component: <PlatformPropertyCard properties={[{
            label: 'Logo Url',
            value: record.productLogoS3Url as string,
            decorator: (value: string) => <PlatformPropertyCardCopyButtonDecorator value={value}/>,
            span: 12,
        }, {
            label: 'Video Url',
            value: record.productVideoUrl,
            decorator: (value: string) => <PlatformPropertyCardCopyButtonDecorator value={value}/>,
            span: 12,
        }]}/>
    }, {
        title: 'Highlights',
        component: <PlatformPropertyCard properties={[{
            label: 'Highlight 1',
            value: record.highlightOne as string,
            span: 12,
        }, {
            label: 'Highlight 2',
            value: record.highlightTwo as string,
            span: 12,
        }, {
            label: 'Highlight 3',
            value: record.highlightThree as string,
            span: 12,
        }]}/>
    }, {
        title: 'Integration',
        component: <PlatformPropertyCard properties={[{
            label: 'Metering Service SNS Topic ARN',
            value: record.meteringServiceSnsTopicArn as string,
            span: 6,
        }]}/>
    }, {
        title: 'Metadata',
        component: <PlatformPropertyCard properties={[
            {
                label: 'SKU',
                value: record.sku as string,
                decorator: (value: string) => <PlatformPropertyCardCopyButtonDecorator value={value}/>,
                span: 6,
            }, {
                label: 'Product Category',
                value: record.productCategories.join(', ') as string,
                span: 6,
            }, {
                label: 'Keywords for search results',
                value: record.keywordsForSearchResults.join(', ') as string,
                span: 12,
            }, {
                label: 'Created By',
                value: typeof record.createdBy !== 'undefined' && record.createdBy !== null ? [record.createdBy.givenName, record.createdBy.familyName].join(' ') : 'n/a',
                rbac: 'UpdateProduct',
                span: 3,
            },
            {
                label: 'Created',
                value: record.created as string,
                formatter: (value: string) => <PlatformPropertyCardTimestampFormatter value={value}/>,
                span: 3,
            }, {
                label: 'Managed By',
                value: typeof record.managedBy !== 'undefined' && record.managedBy !== null ? [record.managedBy.givenName, record.managedBy.familyName].join(' ') : 'n/a',
                rbac: 'UpdateProduct',
                span: 3,
            },
            {
                label: 'Last Updated',
                value: record.updated as string,
                formatter: (value: string) => <PlatformPropertyCardTimestampFormatter value={value}/>,
                span: 3,
            }
        ]}/>
    }] as TabComponentTab[];

    return (
        <TabbedComponent tabs={tabs}/>
    );


};
