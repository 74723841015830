import * as Yup from 'yup';

export const RecordSchema = Yup.object()
                               .shape({
                                          topicArn: Yup.string()
                                                       .required('Required'),
                                          protocol: Yup.string()
                                                       .required('Required'),
                                          endpoint: Yup.string()
                                                       .required('Required'),
                                      });
