export const listMeteringRecords = `
   query listMeteringRecords($filter: MeteringRecordsFilterInput) {
       listMeteringRecords(filter: $filter) {
           items {
              id
              productCode
              usageRecords {
                customerIdentifier
                dimension
                quantity
                timestamp
              }
              created
              updated
           }
       }
   }
`;

export const getMeteringRecord = `
   query getMeteringRecord($id:ID!) {
       getMeteringRecord(id:$id) {
         id
         productCode
        usageRecords {
            customerIdentifier
            dimension
            quantity
            timestamp
         }
         created
         updated
       } 
}`;
