import {createSlice} from '@reduxjs/toolkit';
import {IVisibilitySlice} from "../types";

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 10;

const initialState: IVisibilitySlice = {
    page: DEFAULT_PAGE,
    limit: DEFAULT_PAGE_SIZE,
    keyword: '',
    visible: false,
    columns: [],
};

export const VisibilitySliceFor = (entityKey: string) => {
    const visibilitySlice = createSlice({
                                            name: entityKey,
                                            initialState,
                                            reducers: {
                                                setPage: (state, action) => {
                                                    state.page = action.payload;
                                                },
                                                incrementPage: (state) => {
                                                    state.page++;
                                                },
                                                decrementPage: (state) => {
                                                    state.page--;
                                                },
                                                setPageSize: (state, action) => {
                                                    state.limit = action.payload;
                                                },
                                                resetPageSize: (state) => {
                                                    state.limit = DEFAULT_PAGE_SIZE;
                                                },
                                                setKeyword: (state, action) => {
                                                    state.keyword = action.payload;
                                                },
                                                clearKeyword: (state) => {
                                                    state.keyword = '';
                                                },
                                                show: (state) => {
                                                    state.visible = true;
                                                },
                                                hide: (state) => {
                                                    state.visible = false;
                                                },
                                                toggleVisibility: (state) => {
                                                    state.visible = !state.visible;
                                                },
                                                setTableColumns: (state, action) => {
                                                    state.columns = action.payload;
                                                },
                                                addTableColumn: (state, action) => {
                                                    state.columns.push(action.payload);
                                                },
                                            },
                                        });

    return visibilitySlice;
};
