import {stage} from './stage';

const notificationQueue = {
    prod: {
        arn: 'arn:aws:sqs:us-west-2:426696056333:prod-flexinet-marketplace-aws-notifications-infra--sqsqueueE70CFDBB-YuPODrlWahsa',
    },
    test: {
        arn: 'arn:aws:sqs:us-west-2:198672713296:test-flexinet-marketplace-aws-notifications-infra--sqsqueueE70CFDBB-hin8HSTgQ2aR',
    },
    dev: {
        arn: 'arn:aws:sqs:us-west-2:298878876875:flexinet-marketplace-aws-notifications-infra-dev-sqsqueueE70CFDBB-Wsp1bEMoWUpC',
    },
    ide: {
        arn: 'arn:aws:sqs:us-west-2:298878876875:flexinet-marketplace-aws-notifications-infra-dev-sqsqueueE70CFDBB-Wsp1bEMoWUpC',
    },
};

export default notificationQueue[stage];
