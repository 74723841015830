import {useEffect} from 'react';

import {useParams} from 'react-router-dom';

import {Stack} from '@mantine/core';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    PlatformLoader,
} from '@flexinet/ui-components';
import {Profile} from '../components/Profile';

import {EditPageNavigation} from '../components/EditPageNavigation';
import {useRegistration} from '../hooks/useRegistration.ts';
import {useRegistrationsSelection} from '../hooks/useRegistrationsSelection.ts';
import {useEditPageTools} from '../hooks/useEditPageTools';

import {useRegistrations} from "../hooks/useRegistrations.ts";


export const EditPage = () => {

    const {id: registrationId} = useParams();

    if (typeof registrationId === 'undefined') {
        throw new Error('registrationId is required parameter');
    }

    const {fetchRecords} = useRegistrations();

    const {
        record: registration,
        fetchRecord,
        updateRecord,
    } = useRegistration(registrationId);

    const {handleClearSelection} = useRegistrationsSelection();

    const {
        mode,
        tools,
    } = useEditPageTools();


    useEffect(() => {
        handleClearSelection();
    }, []);

    useEffect(() => {
        fetchRecords();
    }, []);

    useEffect(() => {
        fetchRecord();
    }, [registrationId]);

    if (!registration) {
        return <PlatformLoader message='Loading registration...Please wait'/>;
    }


    return (
        <FormPageLayout
            title={registration.name ? registration.name : registration.id}
            pageNavigation={<EditPageNavigation component={registration}/>}
            tools={tools}
        >


            {mode === 'view' ?
                <Stack gap='lg'>
                    <Profile
                        record={registration}
                    />

                    {/*
                    <AccountsPage/>
*/}

                </Stack>
                :
                <RecordForm
                    record={registration}
                    handleSubmit={updateRecord}
                />}


        </FormPageLayout>
    );
};
