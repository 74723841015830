import {
    Navigate,
    Route,
    Routes,
} from 'react-router-dom';

import {IndexPage} from './pages/IndexPage';
import {AddPage} from './pages/AddPage';
import {EditPage} from "./pages/EditPage.tsx";
import {SimulatePage} from "./pages/SimulatePage.tsx";
import {useVerifiedPermissions} from "../../hooks/useVerifiedPermissions.ts";


export const RegistrationsLayout = () => {

    const {allowedActions} = useVerifiedPermissions()


    return (
        <Routes>
            <Route
                path='/'
                element={
                    allowedActions.includes('ListRegistrations') ? (
                        <IndexPage/>
                    ) : (
                        <Navigate to='/'/>
                    )
                }
            />
            <Route
                path='add'
                element={
                    allowedActions.includes('CreateRegistration') ? (
                        <AddPage/>
                    ) : (
                        <Navigate to='/registrations'/>
                    )
                }
            />
            <Route
                path=':id'
                element={
                    allowedActions.includes('GetRegistration') ? (
                        <EditPage/>
                    ) : (
                        <Navigate to='/registrations'/>
                    )
                }

            >
            </Route>

            <Route
                path='simulate'
                element={
                    allowedActions.includes('SimulateRegistration') ? (
                        <SimulatePage/>
                    ) : (
                        <Navigate to='/registrations'/>
                    )
                }
            />

        </Routes>
    );
};
