import PropTypes from 'prop-types';
import {Field} from 'formik';

import {
    FormikRadioGroup,
    FormikSelect,
    FormikTextInput
} from '@flexinet/ui-components';
import {
    Container,
    Fieldset,
    Text,
    Title
} from "@mantine/core";

import notificationQueue from "../../../config/aws-notification-queue";

export const FormComponent = ({values}: { values: { id: string, protocol?: string | undefined } }) => {

    const editMode = values.id ? true : false;

    return (
        <Container>
            <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Subscription</Text></Title>}
                      px={'xl'}
                      mb={'xl'}>

                <Field
                    name='topicArn'
                    label='Topic'
                    description='Please enter the topic'
                    component={FormikTextInput}
                    disabled={true}
                    withAsterisk
                    my={'xl'}
                />


                <Field
                    name='protocol'
                    label='Protocol'
                    description='Please enter protocol'
                    data={[{
                        value: 'SNS',
                        label: 'SNS'
                    }, {
                        value: 'SQS',
                        label: 'SQS'
                    }, {
                        value: 'EMAIL',
                        label: 'EMAIL'
                    }]}
                    component={FormikSelect}
                    withAsterisk
                    my={'xl'}
                />


                {values.protocol === 'SQS' ? <Field
                        name='endpoint'
                        label='Queue'
                        description='Please select the queue'
                        component={FormikSelect}
                        data={[{
                            value: notificationQueue.arn,
                            label: 'AWS Notifications Queue'
                        }]}
                        withAsterisk
                        my={'xl'}
                    />
                    :
                    <Field
                        name='endpoint'
                        label='Endpoint'
                        description='Please enter the endpoint'
                        component={FormikTextInput}
                        withAsterisk
                        my={'xl'}
                    />}

            </Fieldset>

            {editMode ? <>

                <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Status</Text></Title>} px={'xl'} mb={'xl'}>

                    <Field
                        name='status'
                        label='Status'
                        description='Please enter status'
                        component={FormikRadioGroup}
                        data={[
                            {
                                value: 'Staging',
                                label: 'Staging'
                            },
                            {
                                value: 'Pending',
                                label: 'Pending'
                            },
                            {
                                value: 'Active',
                                label: 'Active'
                            },
                            {
                                value: 'Deleted',
                                label: 'Deleted'
                            },
                            {
                                value: 'Unknown',
                                label: 'Unknown'
                            },]}
                        my={'xl'}
                    />

                </Fieldset>

            </> : null}

        </Container>
    );
};

FormComponent.propTypes = {
    values: PropTypes.object.isRequired,
};
