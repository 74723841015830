import PropTypes from 'prop-types';
import {Field} from 'formik';

import {FormikTextInput} from '@flexinet/ui-components';
import {
    Container,
    Fieldset,
    Text,
    Title
} from "@mantine/core";


export const FormComponent = () => {

    return (
        <Container>
            <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Registration</Text></Title>} px={'xl'} mb={'xl'}>
                <Field
                    name='name'
                    label='Name'
                    description='Please enter the registration name'
                    component={FormikTextInput}
                    withAsterisk
                    my={'xl'}
                />

                <Field
                    name='customerIdentifier'
                    label='Registration Identifier'
                    description='Please enter registration identifier'
                    component={FormikTextInput}
                    withAsterisk
                    my={'xl'}
                />

            </Fieldset>

        </Container>
    );
};

FormComponent.propTypes = {
    values: PropTypes.object.isRequired,
};
