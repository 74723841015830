export const actions = [
    'ViewDashboard',
    'ViewProfile',

    'ListMeteringRecords',
    'GetMeteringRecord',
    'CreateMeteringRecord',
    'UpdateMeteringRecord',
    'DeleteMeteringRecord',

    'ListNotificationSubscriptions',
    'GetNotificationSubscription',
    'CreateNotificationSubscription',
    'UpdateNotificationSubscription',
    'DeleteNotificationSubscription',

    'ListOffers',
    'GetOffer',
    'CreateOffer',
    'UpdateOffer',
    'DeleteOffer',

    'ListProducts',
    'GetProduct',
    'CreateProduct',
    'UpdateProduct',
    'DeleteProduct',

    'ListRegistrations',
    'GetRegistration',
    'CreateRegistration',
    'UpdateRegistration',
    'DeleteRegistration',
    'SimulateRegistration',

];

export const actionConstants = actions.reduce((acc, action) => {
    acc[action.replace(/[A-Z]/g, (match, offset) => (offset > 0 ? '_' : '') + match.toLowerCase())
              .toUpperCase()] = action;
    return acc;
}, {} as Record<string, string>);