import {
    deleteMeteringRecord,
    fetchMeteringRecord,
    saveMeteringRecord,
    updateMeteringRecord,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {MeteringRecord} from '../types';

export const useMeteringRecord = (meteringRecordId?: string) => {


    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, meteringRecordId ? meteringRecordId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof meteringRecordId === 'undefined') {
            throw new Error('meteringRecordId is required parameter');
        }
        dispatch(fetchMeteringRecord(meteringRecordId));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: MeteringRecord) => {
        dispatch(saveMeteringRecord(values));
    };

    const updateRecord = (values: MeteringRecord) => {
        if (typeof meteringRecordId === 'undefined') {
            throw new Error('meteringRecordId is required parameter');
        }

        dispatch(updateMeteringRecord({
                                          id: meteringRecordId,
                                          record: values,
                                      }));
    };

    const deleteRecord = () => {
        if (typeof meteringRecordId === 'undefined') {
            throw new Error('meteringRecordId is required parameter');
        }
        dispatch(deleteMeteringRecord(meteringRecordId));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
