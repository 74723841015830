import {I18n} from '@aws-amplify/core';
import {NavLink} from 'react-router-dom';
import {NotificationSubscription} from '../types';

export const columns = [
    {
        title: I18n.get('Subscription Arn'),
        width: '1fr',
        render: (item: NotificationSubscription) => item.subscriptionArn,
    },
    {
        title: I18n.get('Topic'),
        width: '1fr',
        render: (item: NotificationSubscription) => item.topicArn,
    },
    {
        title: I18n.get('Protocol'),
        width: '1fr',
        render: (item: NotificationSubscription) => item.protocol,
    },
    {
        title: I18n.get('Endpoint'),
        width: '1fr',
        render: (item: NotificationSubscription) => <NavLink
            to={`/notification-subscriptions/${item.id}`}>{item.endpoint}</NavLink>,
    },
    {
        title: I18n.get('Owner'),
        width: '1fr',
        render: (item: NotificationSubscription) => item.owner,
    },
];
