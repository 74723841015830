export const listRegistrations = `
   query listRegistrations {
       listRegistrations {
           items {
              id
              productCode
              customerIdentifier
              customerAWSAccountId
              marketplaceToken
              created
              updated
           }
       }
   }
`;

export const getRegistration = `
   query getRegistration($id:ID!) {
       getRegistration(id:$id) {
         id
         productCode
         customerIdentifier    
         customerAWSAccountId     
         marketplaceToken
         created
         updated
       } 
}`;
