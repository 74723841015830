import {
    Navigate,
    Route,
    Routes,
} from 'react-router-dom';

import {IndexPage} from './pages/IndexPage';
import {AddPage} from './pages/AddPage';
import {EditPage} from "./pages/EditPage.tsx";
import {useVerifiedPermissions} from "../../hooks/useVerifiedPermissions.ts";


export const NotificationSubscriptionsLayout = () => {

    const {allowedActions} = useVerifiedPermissions()


    return (
        <Routes>
            <Route
                path='/'
                element={
                    allowedActions.includes('ListNotificationSubscriptions') ? (
                        <IndexPage/>
                    ) : (
                        <Navigate to='/'/>
                    )
                }
            />
            <Route
                path='add'
                element={
                    allowedActions.includes('CreateNotificationSubscription') ? (
                        <AddPage/>
                    ) : (
                        <Navigate to='/notification-subscriptions'/>
                    )
                }
            />
            <Route
                path=':id'
                element={
                    allowedActions.includes('GetNotificationSubscription') ? (
                        <EditPage/>
                    ) : (
                        <Navigate to='/notification-subscriptions'/>
                    )
                }

            >
            </Route>


        </Routes>
    );
};
