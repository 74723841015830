import {
    deleteNotificationSubscription,
    fetchNotificationSubscription,
    saveNotificationSubscription,
    updateNotificationSubscription,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {NotificationSubscription} from '../types';

export const useNotificationSubscription = (notificationSubscriptionId?: string) => {


    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, notificationSubscriptionId ? notificationSubscriptionId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof notificationSubscriptionId === 'undefined') {
            throw new Error('notificationSubscriptionId is required parameter');
        }
        dispatch(fetchNotificationSubscription(notificationSubscriptionId));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: NotificationSubscription) => {
        dispatch(saveNotificationSubscription(values));
    };

    const updateRecord = (values: NotificationSubscription) => {
        if (typeof notificationSubscriptionId === 'undefined') {
            throw new Error('notificationSubscriptionId is required parameter');
        }

        dispatch(updateNotificationSubscription({
                                                    id: notificationSubscriptionId,
                                                    record: values,
                                                }));
    };

    const deleteRecord = () => {
        if (typeof notificationSubscriptionId === 'undefined') {
            throw new Error('notificationSubscriptionId is required parameter');
        }
        dispatch(deleteNotificationSubscription(notificationSubscriptionId));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
