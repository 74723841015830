export const listOffers = `
   query listOffers {
       listOffers {
           items {
              id
              name
              created
              updated
           }
       }
   }
`;

export const getOffer = `
   query getOffer($id:ID!) {
       getOffer(id:$id) {
         id
         name
       } 
}`;
