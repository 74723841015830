import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {Product} from '../types';

export const columns = [
    {
        title: I18n.get('Product Title'),
        width: '1fr',
        render: (item: Product) => <NavLink to={`/products/${item.id}`}>{item.productTitle}</NavLink>,
    },
    {
        title: I18n.get('Product ID'),
        width: '1fr',
        render: (item: Product) => item.productId,
    },
    {
        title: I18n.get('Product Code'),
        width: '1fr',
        render: (item: Product) => item.productCode,
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: Product) => formatTimestamp(item.created as string),
    },
];
