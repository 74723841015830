import {
    configureStore,
    Store
} from '@reduxjs/toolkit';
import {ProductsReducer} from "../features/products/reducers";
import {OffersReducer} from "../features/offers/reducers";
import {RegistrationsReducer} from "../features/registrations/reducers";
import {MeteringRecordsReducer} from "../features/metering-records/reducers";
import {NotificationSubscriptionsReducer} from "../features/notification-subscriptions/reducers";


const reducers = {
    registrations: RegistrationsReducer,
    meteringRecords: MeteringRecordsReducer,
    offers: OffersReducer,
    products: ProductsReducer,
    notificationSubscriptions: NotificationSubscriptionsReducer
};

// added this middleware  to disable this error - A non-serializable value was detected in the state, in the path...
// @todo disable if better way is found
export const store: Store = configureStore({
                                               reducer: reducers,
                                               middleware: (getDefaultMiddleware) =>
                                                   getDefaultMiddleware({
                                                                            serializableCheck: false,
                                                                        }),
                                           });


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch