import {PlatformNavbarMenuItem} from "@flexinet/ui-components";
import {v4 as uuidv4} from 'uuid';
import {
    AiOutlineAppstore,
    AiOutlineDollarCircle,
    AiOutlineHome
} from 'react-icons/ai'

import {TfiRulerAlt} from "react-icons/tfi";


import {IconContext} from "react-icons";
import {MdOutlineBusinessCenter} from "react-icons/md";

export const getMenuItems = () => {

    return [
        {
            id: uuidv4(),
            link: '/dashboard',
            label: 'Dashboard',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineHome/></IconContext.Provider>,
            rbac: 'ViewDashboard',
        },
        {
            id: uuidv4(),
            link: '/registrations',
            label: 'Registrations',
            icon: <IconContext.Provider value={{size: '2em'}}><MdOutlineBusinessCenter/></IconContext.Provider>,
            rbac: 'ListRegistrations',
        },
        {
            id: uuidv4(),
            link: '/offers',
            label: 'Offers',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineDollarCircle/></IconContext.Provider>,
            rbac: 'ListOffers',
        },
        {
            id: uuidv4(),
            link: '/products',
            label: 'Products',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineAppstore/></IconContext.Provider>,
            rbac: 'ListProducts',
        },
        {
            id: uuidv4(),
            link: '/metering-records',
            label: 'Metering Records',
            icon: <IconContext.Provider value={{size: '2em'}}><TfiRulerAlt/></IconContext.Provider>,
            rbac: 'ListMeteringRecords',
        }] as PlatformNavbarMenuItem[];

};

