import PropTypes from 'prop-types';

import {
    Group,
    Stack,
    Text,
} from '@mantine/core';
import {
    MeteringRecord,
    MeteringRecordDimension,
} from '../types';
import {
    PlatformPropertyCard,
    PlatformPropertyCardCopyButtonDecorator,
    PlatformPropertyCardProperty,
    PlatformPropertyCardTimestampFormatter
} from "@flexinet/ui-components";
import {useAllowedItems} from "../../../hooks/useAllowedProperties.ts";

const DimensionsTable = ({dimensions}: { dimensions: MeteringRecordDimension[] }) => {
    return (
        <>
            {dimensions.map((dimension, index) => (<Stack key={index} gap={'xs'}>
                <Group ml={'1em'} gap={'xs'}><Text
                    fw={600}>Name:</Text><Text>{dimension.name}</Text></Group>
                <Group ml={'1em'} gap={'xs'}><Text
                    fw={600}>Value:</Text><Text>{dimension.value}</Text></Group>
            </Stack>))}
        </>
    );
};

DimensionsTable.propTypes = {
    dimensions: PropTypes.array,
};

export const Profile = ({record}: { record: MeteringRecord }) => {

    const properties = [
        {
            label: 'Product Code',
            value: record.productCode as string,
            span: 12,
        }, {
            label: 'Created By',
            value: typeof record.createdBy !== 'undefined' && record.createdBy !== null ? [record.createdBy.givenName, record.createdBy.familyName].join(' ') : 'n/a',
            rbac: 'UpdateMeteringRecord',
            span: 3,
        },
        {
            label: 'Created',
            value: record.created as string,
            formatter: (value: string) => <PlatformPropertyCardTimestampFormatter value={value}/>,
            span: 3,
        }, {
            label: 'Managed By',
            value: typeof record.managedBy !== 'undefined' && record.managedBy !== null ? [record.managedBy.givenName, record.managedBy.familyName].join(' ') : 'n/a',
            rbac: 'UpdateMeteringRecord',
            span: 3,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatter: (value: string) => <PlatformPropertyCardTimestampFormatter value={value}/>,
            span: 3,
        }] as PlatformPropertyCardProperty[];

    const {filterAllowed} = useAllowedItems()
    const allowedProperties = filterAllowed(properties) as PlatformPropertyCardProperty[];

    return (
        <>
            <PlatformPropertyCard properties={allowedProperties}/>
            {record.usageRecords.length > 0 && record.usageRecords.map(usageRecord => {
                const usageRecordsProperties = [
                    {
                        label: 'Customer Identifier',
                        value: usageRecord.customerIdentifier as string,
                        decorator: (value: string) => <PlatformPropertyCardCopyButtonDecorator value={value}/>,
                        span: 3,
                    }, {
                        label: 'Dimension',
                        value: usageRecord.dimension as string,
                        span: 3,
                    }, {
                        label: 'Quantity',
                        value: usageRecord.quantity,
                        span: 3,
                    },
                    {
                        label: 'Timestamp',
                        value: usageRecord.timestamp as string,
                        formatAs: 'Timestamp',
                        span: 3,
                    }] as PlatformPropertyCardProperty[];


                return <PlatformPropertyCard properties={usageRecordsProperties}/>
            })}

        </>
    );


};

Profile.propTypes = {
    record: PropTypes.shape({
                                name: PropTypes.string,
                                description: PropTypes.string,
                                status: PropTypes.string,
                                createdBy: PropTypes.shape({
                                                               givenName: PropTypes.string,
                                                               familyName: PropTypes.string,
                                                           }),
                                managedBy: PropTypes.shape({
                                                               givenName: PropTypes.string,
                                                               familyName: PropTypes.string,
                                                           }),
                                created: PropTypes.string,
                                updated: PropTypes.string,
                            }),
};


