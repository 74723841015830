import {
    getNotificationSubscription,
    listNotificationSubscriptions
} from './queries';
import {
    createNotificationSubscription,
    deleteNotificationSubscription,
    updateNotificationSubscription,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';

import {
    CreateNotificationSubscription,
    DeleteNotificationSubscription,
    GetNotificationSubscription,
    ListNotificationSubscriptions,
    NotificationSubscription,
    UpdateNotificationSubscription
} from "../types.ts";
import {
    notifySuccess,
    showErrorNotification,
    showErrorNotifications,
} from "../../../components/ServiceNotifications.tsx";
import {V6Client} from "@aws-amplify/api-graphql";
import {generateClient} from "aws-amplify/api";


const region = 'us-east-1'


let client: V6Client
try {
    client = generateClient();
} catch (e: any) {
    showErrorNotification(e)
}


export const fetchRecords = async (topicArn?: string) => {

    const variables = {
        region: region,
    } as { region: string, topicArn: string | undefined };

    if (topicArn !== null && topicArn !== undefined && topicArn !== '') {
        variables.topicArn = topicArn;
    }


    try {
        let items: NotificationSubscription[] = [];
        const response = await client.graphql<GraphQLQuery<ListNotificationSubscriptions>>({
                                                                                               query: listNotificationSubscriptions,
                                                                                               variables: variables
                                                                                           });

        items = [...response.data?.listSubscriptions.items as NotificationSubscription[]];
        console.debug('fetchRecords items: ', items);
        return items;

    } catch (e) {
        showErrorNotifications(e)
    }
};

export const fetch = async (subscriptionArn: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetNotificationSubscription>>({
                                                                                             query: getNotificationSubscription,
                                                                                             variables: {
                                                                                                 region: region,
                                                                                                 subscriptionArn: subscriptionArn
                                                                                             },
                                                                                         });
        return response.data?.getSubscription;
    } catch (e) {
        showErrorNotifications(e)
    }
};

export const add = async (payload: object) => {


    try {
        const response = await client.graphql<GraphQLQuery<CreateNotificationSubscription>>({
                                                                                                query: createNotificationSubscription,
                                                                                                variables: {
                                                                                                    region: region,
                                                                                                    input: payload
                                                                                                },
                                                                                            });
        console.debug('in the service response', response);
        notifySuccess('Subscription created');
        return response.data?.createSubscription;
    } catch (e) {
        showErrorNotifications(e)
    }
};

export const update = async (id: string, record: NotificationSubscription) => {

    console.debug('IN UPDATE', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateNotificationSubscription>>({
                                                                                                query: (updateNotificationSubscription),
                                                                                                variables: {
                                                                                                    region: region,
                                                                                                    input: record
                                                                                                },
                                                                                            });
        notifySuccess('Subscription updated');
        return response.data?.updateSubscription;
    } catch (e) {
        showErrorNotifications(e)
    }
};

export const deleteRecord = async (subscriptionArn: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteNotificationSubscription>>({
                                                                                                query: (deleteNotificationSubscription),
                                                                                                variables: {
                                                                                                    region: region,
                                                                                                    input: {
                                                                                                        subscriptionArn: subscriptionArn
                                                                                                    }
                                                                                                },

                                                                                            });
        notifySuccess('Subscription deleted');
        return response.data?.deleteSubscription;
    } catch (e) {
        showErrorNotifications(e)
    }
};
