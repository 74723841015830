export const createOffer = `
    mutation createOffer($input: CreateOfferInput!) {
        createOffer(input:$input) {
            id
            name
    }
}`;

export const updateOffer = `
    mutation updateOffer($input: UpdateOfferInput!) {
        updateOffer(input:$input){
            id
            name
        }
}`;

export const deleteOffer = `
    mutation deleteOffer($input: DeleteOfferInput!) {
        deleteOffer(input:$input) 
}`;
