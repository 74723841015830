import {stage} from '../stage';
import {ResourcesConfig} from "aws-amplify";

const cognito = {
    prod: {
        domain: 'portal.flexi.network',
        redirectSignIn: ['https://marketplace.flexi.network'],
        redirectSignOut: ['https://portal.flexi.network'],
        region: 'us-west-2',
        userPoolId: 'us-west-2_bxdNeqDGn',
        userPoolClientId: '467rlh361q573kme7313svd3sb',
        identityPoolId: 'us-west-2:5bad2932-df41-4b25-a2e2-15b8238a32c6',
    },
    test: {
        domain: 'portal.test.flexi.network',
        redirectSignIn: ['https://marketplace.test.flexi.network'],
        redirectSignOut: ['https://portal.test.flexi.network'],
        region: 'us-west-2',
        userPoolId: 'us-west-2_UuiFFepVO',
        userPoolClientId: '4l6lu4764mgkk0s6jfq5ebq2c8',
        identityPoolId: 'us-west-2:e32cbfbe-5359-4fa8-ab47-abfbd17f6f1b',
    },
    dev: {
        domain: 'portal.dev.flexi.network',
        redirectSignIn: ['https://marketplace.dev.flexi.network'],
        redirectSignOut: ['https://portal.dev.flexi.network'],
        region: 'us-west-2',
        userPoolId: 'us-west-2_INBgfMat1',
        userPoolClientId: '52m9svts4nvks7qi0ft8lt0oct',
        identityPoolId: 'us-west-2:4d212a6a-3a46-4d38-a43d-b434cd92f10e',
    },
    ide: {
        domain: 'localhost:8011',
        redirectSignIn: ['https://localhost:8014'],
        redirectSignOut: ['https://localhost:8011'],
        region: 'us-west-2',
        userPoolId: 'us-west-2_INBgfMat1',
        userPoolClientId: '52m9svts4nvks7qi0ft8lt0oct',
        identityPoolId: 'us-west-2:4d212a6a-3a46-4d38-a43d-b434cd92f10e',
    },
};

export const config = {
    Cognito: {
        // Amazon Cognito User Pool ID
        userPoolId: cognito[stage].userPoolId,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolClientId: cognito[stage].userPoolClientId,

        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: cognito[stage].identityPoolId,

        // OPTIONAL - Set to true to use your identity pool's unauthenticated role when user is not logged in
        allowGuestAccess: false,

        loginWith: {
            oauth: {
                // Domain name
                domain: cognito[stage].domain,

                // Authorized scopes
                scopes: [
                    'phone',
                    'email',
                    'profile',
                    'openid',
                    'aws.cognito.signin.user.admin',
                ],

                // Callback URL
                redirectSignIn: cognito[stage].redirectSignIn,

                // Sign out URL
                redirectSignOut: cognito[stage].redirectSignOut,

                // 'code' for Authorization code grant,
                // 'token' for Implicit grant
                responseType: 'token',
            },
        }


    }
} as ResourcesConfig['Auth'];