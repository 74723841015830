import {useEffect} from 'react';

import {useParams} from 'react-router-dom';

import {Stack} from '@mantine/core';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    PlatformLoader,
} from '@flexinet/ui-components';
import {Profile} from '../components/Profile';

import {EditPageNavigation} from '../components/EditPageNavigation';
import {useOffer} from '../hooks/useOffer.ts';
import {useOffersSelection} from '../hooks/useOffersSelection.ts';
import {useEditPageTools} from '../hooks/useEditPageTools';

import {useOffers} from "../hooks/useOffers.ts";


export const EditPage = () => {

    const {id: offerId} = useParams();

    if (typeof offerId === 'undefined') {
        throw new Error('offerId is required parameter');
    }

    const {fetchRecords} = useOffers();

    const {
        record: offer,
        fetchRecord,
        updateRecord,
    } = useOffer(offerId);

    const {handleClearSelection} = useOffersSelection();

    const {
        mode,
        tools,
    } = useEditPageTools();


    useEffect(() => {
        handleClearSelection();
    }, []);

    useEffect(() => {
        fetchRecords();
    }, []);

    useEffect(() => {
        fetchRecord();
    }, [offerId]);

    if (!offer) {
        return <PlatformLoader message='Loading offer...Please wait'/>;
    }


    return (
        <FormPageLayout
            title={offer.name ? offer.name : offer.id}
            pageNavigation={<EditPageNavigation component={offer}/>}
            tools={tools}
        >


            {mode === 'view' ?
                <Stack gap='lg'>
                    <Profile
                        record={offer}
                    />

                    {/*
                    <AccountsPage/>
*/}

                </Stack>
                :
                <RecordForm
                    record={offer}
                    handleSubmit={updateRecord}
                />}


        </FormPageLayout>
    );
};
