import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {MeteringRecord} from '../types';

export const columns = [
    {
        title: I18n.get('Product Code'),
        width: '1fr',
        render: (item: MeteringRecord) => <NavLink to={`/metering-records/${item.id}`}>{item.productCode}</NavLink>,
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: MeteringRecord) => formatTimestamp(item.created as string),
    },
];
