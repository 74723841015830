import {
    createBrowserRouter,
    RouterProvider
} from 'react-router-dom';
import {PublicHomePage} from './features/home/pages/PublicHomePage';
import {SignOutPage} from './features/home/pages/SignOutPage';

import {IndexPage as DashboardPage} from "./features/dashboard/pages/IndexPage";
import {AppLayout} from "./layout/AppLayout";
import {RequireAuth} from "./utils/RequireAuth.tsx";
import {UserAttributesContextProvider} from "./context/UserAttributesContext.tsx";
import {theme} from "./theme.ts";
import {MantineProvider} from "@mantine/core";
import {Notifications} from "@mantine/notifications";
import {ModalsProvider} from "@mantine/modals";

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';

import {ProductsLayout} from "./features/products/layout.tsx";
import {OffersLayout} from "./features/offers/layout.tsx";
import {RegistrationsLayout} from "./features/registrations/layout.tsx";
import {MeteringRecordsLayout} from "./features/metering-records/layout.tsx";
import {NotificationSubscriptionsLayout} from "./features/notification-subscriptions/layout.tsx";
import {AuthProvider} from "./context/AuthContext.tsx";
import {VerifiedPermissionsProvider} from './context/VerifiedPermissionsProvider.tsx';
import {actions} from './config/rbac/actions.ts';


const router = createBrowserRouter([{
    path: '/',
    element: <AppLayout/>,
    children: [{
        index: true,
        element: <PublicHomePage/>
    }, {
        path: 'sign-out',
        element: <SignOutPage/>
    }, {
        path: 'dashboard',
        element: <RequireAuth><DashboardPage/></RequireAuth>
    }, {
        path: 'products/*',
        element: <ProductsLayout/>
    }, {
        path: 'registrations/*',
        element: <RegistrationsLayout/>
    }, {
        path: 'notification-subscriptions/*',
        element: <NotificationSubscriptionsLayout/>
    }, {
        path: 'metering-records/*',
        element: <MeteringRecordsLayout/>
    }, {
        path: 'offers/*',
        element: <OffersLayout/>
    }]
}])


export const App = () => {

    return (
        <MantineProvider theme={theme}>
            <AuthProvider>
                <VerifiedPermissionsProvider actions={actions}>
                    <UserAttributesContextProvider>
                        <ModalsProvider>
                            <Notifications position={'bottom-left'}/>
                            <RouterProvider router={router}/>
                        </ModalsProvider>
                    </UserAttributesContextProvider>
                </VerifiedPermissionsProvider>
            </AuthProvider>
        </MantineProvider>
    );
}

