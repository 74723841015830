import {useEffect} from 'react';

import {useParams} from 'react-router-dom';

import {Stack} from '@mantine/core';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    PlatformLoader,
} from '@flexinet/ui-components';
import {Profile} from '../components/Profile';

import {EditPageNavigation} from '../components/EditPageNavigation';
import {useNotificationSubscription} from '../hooks/useNotificationSubscription.ts';
import {useNotificationSubscriptionsSelection} from '../hooks/useNotificationSubscriptionsSelection.ts';
import {useEditPageTools} from '../hooks/useEditPageTools';


export const EditPage = () => {

    const {id: notificationSubscriptionId} = useParams();

    if (typeof notificationSubscriptionId === 'undefined') {
        throw new Error('notificationSubscriptionId is required parameter');
    }


    const {
        record: notificationSubscription,
        fetchRecord,
        updateRecord,
    } = useNotificationSubscription(notificationSubscriptionId);

    const {handleClearSelection} = useNotificationSubscriptionsSelection();

    const {
        mode,
        tools,
    } = useEditPageTools();


    useEffect(() => {
        handleClearSelection();
    }, []);


    useEffect(() => {
        fetchRecord();
    }, [notificationSubscriptionId]);

    if (!notificationSubscription) {
        return <PlatformLoader message='Loading notificationSubscription...Please wait'/>;
    }


    return (
        <FormPageLayout
            title={notificationSubscription.name ? notificationSubscription.name : notificationSubscription.id}
            pageNavigation={<EditPageNavigation component={notificationSubscription}/>}
            tools={tools}
        >


            {mode === 'view' ?
                <Stack gap='lg'>
                    <Profile
                        record={notificationSubscription}
                    />

                    {/*
                    <AccountsPage/>
*/}

                </Stack>
                :
                <RecordForm
                    record={notificationSubscription}
                    handleSubmit={updateRecord}
                />}


        </FormPageLayout>
    );
};
