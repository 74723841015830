export const createRegistration = `
    mutation createRegistration($input: CreateRegistrationInput!) {
        createRegistration(input:$input) {
            id
            productCode
            customerIdentifier       
            customerAWSAccountId
            created
            updated  
    }
}`;

export const updateRegistration = `
    mutation updateRegistration($input: UpdateRegistrationInput!) {
        updateRegistration(input:$input){
            id
            productCode
            customerIdentifier  
            customerAWSAccountId
            created
            updated       
        }
}`;

export const deleteRegistration = `
    mutation deleteRegistration($input: DeleteRegistrationInput!) {
        deleteRegistration(input:$input) 
}`;
