import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update,
} from '../services/OffersService.ts';


import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';
import {Offer} from '../types';

const entityKey = 'offers';

interface OffersState {
    byId: { [key: string]: Offer },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string
}

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as OffersState;


const normalizeRecord = (record: Offer) => {
    return Object.assign({}, record);
};

// generates pending, fulfilled and rejected
export const fetchOffers = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    () => {

        return fetchRecords({})
            .then((response) => {
                if (response) {
                    return response.map((record: Offer) => {
                        return normalizeRecord(record);
                    });
                }
            });
    },
);

export const fetchOffer = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return normalizeRecord(response);
                }
            });
    });

export const saveOffer = createAsyncThunk(
    `${entityKey}/add`,
    (payload: Offer) => {
        return add(payload)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const updateOffer = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record,
     }: { id: string, record: Offer }) => {

        return update(id, record)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    },
);

export const deleteOffer = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    },
);


export const storeOffer = createAsyncThunk(
    `${entityKey}/store`,
    (payload: Offer) => {
        return normalizeRecord(payload);
    },
);


const componentsSlice = createSlice({
                                        name: entityKey,
                                        initialState,
                                        reducers: {
                                            clearState: () => initialState,
                                        },
                                        extraReducers: (builder) => {
                                            // FETCH MANY
                                            fetchManyCase(builder, fetchOffers, entityKey);

                                            // FETCH ONE
                                            fetchOneCase(builder, fetchOffer);

                                            // SAVE ONE
                                            saveOneCase(builder, saveOffer);

                                            // UPDATE ONE
                                            updateOneCase(builder, updateOffer);

                                            updateOneCase(builder, storeOffer);

                                            // DELETE ONE
                                            deleteOneCase(builder, deleteOffer);
                                        },
                                    });

export default componentsSlice.reducer;
