export const createMeteringRecord = `
    mutation createMeteringRecord($input: CreateMeteringRecordInput!) {
        createMeteringRecord(input:$input) {
            id
            productCode
            usageRecords {
                customerIdentifier
                dimension
                quantity
                timestamp
            }
            created
            updated  
    }
}`;

export const updateMeteringRecord = `
    mutation updateMeteringRecord($input: UpdateMeteringRecordInput!) {
        updateMeteringRecord(input:$input){
            id
            productCode
            usageRecords {
                customerIdentifier
                dimension
                quantity
                timestamp
            }
            created
            updated       
        }
}`;

export const deleteMeteringRecord = `
    mutation deleteMeteringRecord($input: DeleteMeteringRecordInput!) {
        deleteMeteringRecord(input:$input) 
}`;
