export const listNotificationSubscriptions = `
   query listNotificationSubscriptions($region:String!, $topicArn:String) {
       listSubscriptions(region:$region, topicArn: $topicArn) {
           items {
            subscriptionArn
            endpoint
            owner
            protocol
            topicArn
           }
       }
   }
`;

export const getNotificationSubscription = `
   query getNotificationSubscription($region:String!, $subscriptionArn:String!) {
       getSubscription(region:$region, subscriptionArn:$subscriptionArn) {
            subscriptionArn
            endpoint
            owner
            protocol
            topicArn
       } 
}`;
