import {useVerifiedPermissions} from "./useVerifiedPermissions.ts";

export const useAllowedItems = () => {

    const {allowedActions} = useVerifiedPermissions()

    const filterAllowed = (items: { rbac?: string }[]) => {

        return items.filter(item => {

            // if no rbac is specified allow it
            if (typeof item.rbac === 'undefined') {
                return true;
            } else {
                // if rbac property is specified - evaluate it and return accordingly
                if (allowedActions.includes(item.rbac)) {
                    return true;
                }
                return false;

            }
        });

    };

    return {
        filterAllowed,
    };

};
