export const listProducts = `
   query listProducts {
       listProducts {
           items {
              id
              productTitle
              shortDescription
              productId
              productArn
              productCode
              status
              sku
              productLogoS3Url
              productVideoUrl
              highlightOne
              highlightTwo
              highlightThree
              productCategories
              keywordsForSearchResults
              meteringServiceSnsTopicArn
              created
              updated
           }
       }
   }
`;

export const getProduct = `
   query getProduct($id:ID!) {
       getProduct(id:$id) {
        id
        productTitle
        shortDescription
        longDescription
        productId
        productArn
        productCode
        status
        sku
        productLogoS3Url
        productVideoUrl
        highlightOne
        highlightTwo
        highlightThree
        productCategories
        keywordsForSearchResults
        meteringServiceSnsTopicArn
        created
        updated
       } 
}`;
