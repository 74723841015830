export const pageFilter = (items: { id: string }[], page: number, limit: number) => {
    if (!Array.isArray(items)) {
        throw new Error('items in page filter should be an array');
    }

    if (typeof page === 'undefined' || page === null) {
        throw new Error('page in page filter should be an integer');
    }

    if (typeof limit === 'undefined' || limit === null) {
        throw new Error('limit in page filter should be an integer');
    }

    return items.slice((page - 1) * limit, page * limit);
};

export const keywordFilter = (items: { id: string }[], keyword: string) => {
    if (!Array.isArray(items)) {
        throw new Error('items in keyword filter should be an array');
    }

    if (typeof keyword === 'undefined' || keyword === '') {
        throw new Error(
            'keyword in keyword filter should not be undefined or empty string'
        );
    }

    return items.filter((item) => {
        return searchInObject(item, keyword);
    });
};

function searchInString(subjectString: string, keyword: string) {
    if (typeof subjectString === 'undefined') {
        throw new Error(
            'subject string in search string method should not be undefined'
        );
    }

    if (typeof subjectString !== 'string') {
        throw new Error('subject string should be a string');
    }

    if (subjectString === '') {
        return false;
    }

    if (typeof keyword === 'undefined') {
        throw new Error(
            'keyword in search string method should not be undefined'
        );
    }

    if (typeof keyword !== 'string') {
        throw new Error(
            'keyword in search string method should be a string. got: ' + keyword
        );
    }

    if (keyword === '') {
        return false;
    }

    return subjectString.toLowerCase()
                        .indexOf(keyword.toLowerCase()) !== -1;
}


function searchInObject(obj: { [key: string]: any }, keyword: string) {
    if (typeof keyword === 'undefined' || keyword === '') {
        return false;
    }

    const evals: boolean[] = [];
    for (const property in obj) {
        if (typeof obj[property] === 'object') {
            evals.push(searchInObject(obj[property], keyword));
        } else if (typeof obj[property] === 'string') {
            evals.push(searchInString(obj[property], keyword));
        }
    }

    return evals.includes(true);
}

export const visibilityFilter = (items: { id: string }[], page: number, limit: number, keyword: string) => {
    if (!Array.isArray(items)) {
        throw new Error('Invalid Items Array in Visibility Filter');
    }

    let filteredByKeyword = items;
    if (typeof keyword !== 'undefined' && keyword !== '') {
        filteredByKeyword = keywordFilter(items, keyword);
    }

    let visibleItems = filteredByKeyword;
    if (typeof page !== 'undefined' && typeof limit !== 'undefined') {
        visibleItems = pageFilter(filteredByKeyword, page, limit);
    }

    return visibleItems;
};
